import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, margins, fontSizes, Icon }
  from '../v2/StyledComponents';
import { mobileThresholdPixels } from './styledComponents';
import facebookWhite from '../../../assets/footer/facebook.svg';
import instagramWhite from '../../../assets/footer/instagram.svg';
import facebookNavy from '../../../assets/social/facebook.navy.svg';
import instagramNavy from '../../../assets/social/instagram.navy.svg';
import MobileApps from './MobileApps';

const StyledIcon = styled(Icon)`
  margin: 7px 24px 0px 0px;
  width: 46px;
  height: 46px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} ${margins.xs} 35px;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  line-height: 30px;
  font-size: ${fontSizes.m};
  color: ${props => props.navy ? colors.navy : colors.white};
  margin: 0px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
  }
`;

const FooterMobileAndSocial = ({ inverse }) => (
  <React.Fragment>
    <Text navy={inverse}>{'Téléchargez notre app'}</Text>
    <MobileApps inverse={inverse} />
    <Text navy={inverse}>{'Et laissez-vous inspirer'}</Text>
    <div>
      <a
        target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/tilli.couture/"
      >
        <StyledIcon
          src={inverse ? facebookNavy : facebookWhite}
          alt="page facebook de Tilli un service de retouches à domicile"
        />
      </a>
      <a
        target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/tilli.couture/"
      >
        <StyledIcon
          src={inverse ? instagramNavy : instagramWhite}
          alt="page instagram de Tilli une retoucherie à domicile"
        />
      </a>

    </div>
  </React.Fragment>
);

FooterMobileAndSocial.propTypes = {
  inverse: PropTypes.bool,
};

FooterMobileAndSocial.defaultProps = {
  inverse: false,
};

export default FooterMobileAndSocial;
