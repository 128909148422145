import photoMailys from '../../../assets/feedbacks/Mailys.png';
import photoLea from '../../../assets/feedbacks/Lea.png';
import photoPauline from '../../../assets/feedbacks/Pauline.png';
import photoFatimZahra from '../../../assets/feedbacks/FatimZahra.png';
import photoRaphael from '../../../assets/feedbacks/Raphael.png';
import photoPierre from '../../../assets/feedbacks/Pierre.png';
import photoAlexia from '../../../assets/feedbacks/Alexia.png';

const customerFeedbacks = {
  ride: {
    name: 'Maïlys',
    jobDescription: 'a fait retoucher ses rideaux par Carole',
    // eslint-disable-next-line max-len
    feedback: 'Le service est tellement novateur ! Carole rend l’expérience Tilli remplie de valeurs et d’engagement ! Ce n’est pas seulement une couturière c’est une belle âme et cela se sent ! Elle met beaucoup de coeur dans ce qu’elle fait et donne inéluctablement envie de faire de nouveau appel à Tilli !',
    rating: 5,
    photo: photoMailys,
  },
  robe: {
    name: 'Léa',
    jobDescription: 'a fait retoucher sa robe de soirée par Mélanie',
    // eslint-disable-next-line max-len
    feedback: 'Mélanie s’est occupée parfaitement de moi, elle a pris le temps de comprendre ce que je voulais. Un travail sur mesure !',
    rating: 5,
    photo: photoLea,
  },
  haut: {
    name: 'Pauline',
    jobDescription: 'a fait retoucher sa chemise par Gabriel',
    // eslint-disable-next-line max-len
    feedback: 'Top experience avec Tilli. Mon couturier était très professionnel et très gentil. Le travail qu’il a fourni était impeccable et les finitions sont très belles. Je suis ravie du service et le recommande vivement à mon entourage ! Merci !',
    rating: 5,
    photo: photoPauline,
  },
  mari: {
    name: 'Fatim-Zahra',
    jobDescription: 'a fait retoucher sa robe de mariée par Alexandra',
    // eslint-disable-next-line max-len
    feedback: 'Oui j’ai passé des moments exceptionnels avec Alexandra. J’avais fait appel à elle au début pour retoucher ma robe de mariée, mais j’ai fini par lui confier 2 autres robes et le résultat est plus qu’époustouflant. Ma robe de mariée qui était très grande pour moi au début, qui baillait de partout et qui était trop longue me va maintenant comme un gant. On dirait qu’elle a été faite sur mesure pour moi. Je suis plus que ravie et je n’hésiterai pas à refaire appel à elle.',
    rating: 5,
    photo: photoFatimZahra,
  },
  mant: {
    name: 'Raphaël',
    jobDescription: 'a fait retoucher sa veste par Khadija',
    feedback: 'Super expérience, je suis fan !',
    rating: 5,
    photo: photoRaphael,
  },
  pant: {
    name: 'Pierre',
    jobDescription: 'a fait retoucher son pantalon de tailleur par Chantal',
    feedback: 'Très bonne prestation de Chantal. Très bons conseils et très professionnelle.',
    rating: 5,
    photo: photoPierre,
  },
  ling: {
    name: 'Alexia',
    jobDescription: 'a fait retoucher ses maillots de bains par Khadija',
    feedback: 'Services de très bonne qualité et très pratique.',
    rating: 5,
    photo: photoAlexia,
  },
};

export default customerFeedbacks;
