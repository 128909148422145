import React from 'react';
import styled from 'styled-components';

import FooterMobileAndSocial from './FooterMobileAndSocial';
import {
  ContainerFooter, Content, colors, margins, fontWeight, mobileThresholdPixels,
} from '../v2/StyledComponents';

import { ratingData } from './ratingData';

import logoTilli from '../../../assets/logos/tilli.white.svg';

const StyledContainerFooter = styled(ContainerFooter)`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 90px;
  
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 30px;
    padding-bottom: 90px;
  }
`;

const StyledContent = styled(Content)`
width: auto;
padding: ${margins.x2l} 0px;
background-color: ${colors.navy};
display: flex;
flex-direction: column;
align-items: center;
margin: auto;

@media (max-width: ${mobileThresholdPixels}) {
  width: auto;
  margin: 0px 42px;
}
`;

const LogoContainer = styled.div`
  width: 100%;
  margin: 40px 0px;
`;

const LogoImg = styled.img`
  width: 100%;
  margin-bottom: ${margins.s};
`;

const LinkText = styled.div`
  color: white;
  font-family: Roboto;
  font-size: 14px;
  font-weight: ${fontWeight.light};
  line-height: 24px;
  cursor: ${props => props.noCursor ? 'auto' : 'pointer'};

  @media (max-width: ${mobileThresholdPixels}) {
    font-weight: ${fontWeight.regular};
    font-size: 12px;
    line-height: 20px;
    ${props => props.link && `padding-top: ${margins.s};`}
  }
`;

const LinkTextInline = LinkText.withComponent('span');

const renderRating = <LinkTextInline>{`Tilli.fr a reçu la note de ${ratingData.scoring} / 10 sur ${ratingData.numberOfReviews} avis.`}</LinkTextInline>;

const FooterLightVersion = () => (
  <StyledContainerFooter>
    <StyledContent>
      <LogoContainer>
        <LogoImg src={logoTilli} alt={'logo Tilli'} />
        {renderRating}
      </LogoContainer>
      <FooterMobileAndSocial smallTopMargin />
    </StyledContent>
  </StyledContainerFooter>
);

export default FooterLightVersion;
