import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../../tilli-components/src/components/styledComponents';
import withAppContext from '../../../withAppContext';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Table = styled.table`
  margin-top: 50px;
  width: 760px;
  border: 1px solid ${colors.navy};
  border-bottom-width: 10px;
  border-collapse: separate;
  border-spacing: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
  }
`;

const TRHeader = styled.thead`
  background-color: ${colors.navy};
  color: ${colors.white};
`;

const HeaderType = styled.th`
  font-weight: bold;
  font-size: 16px;
  padding: 14px 0px;
`;

const HeaderPrice = styled.div`
  flex: 1;
  font-size: 13px;
  font-weight: normal;
  padding: 7px;
`;

const CategoryName = styled.td`
  font-weight: bold;
  font-size: 16px;
  color: ${colors.navy};
  padding: 20px;
`;

const CategoryNameEmpty = styled.td`
  border-left: solid 1px ${colors.navy};
`;

const ItemName = styled.td`
  padding: 10px 20px;
  color: black;
  font-size: 13px;
`;

const ItemPriceContainer = styled.td`
  border-left: solid 1px ${colors.navy};
`;

const ItemPrice = styled.div`
  font-weight: normal;
  font-size: 13px;
  color: #000000;
  text-align: center;
  min-width: 60px;
`;

function renderItemsDesktop(category) {
  const itemsToRender = category.items;
  return (
    itemsToRender
      .filter(item => !item.hidden)
      .map(item => (
        <tr key={item.id}>
          <ItemName>{item.name}</ItemName>
          <ItemPriceContainer>
            {item.prices?.SIMPLE &&
              <Row>
                <ItemPrice>{item.prices.SIMPLE}€</ItemPrice>
                {item.liningPrices?.SIMPLE ?
                  <ItemPrice>+{item.liningPrices.SIMPLE}€</ItemPrice> : <ItemPrice />
                }
              </Row>
            }
          </ItemPriceContainer>
          <ItemPriceContainer>
            {item.prices?.COMPLEX &&
              <Row>
                <ItemPrice>{item.prices.COMPLEX}€</ItemPrice>
                {item.liningPrices?.COMPLEX ?
                  <ItemPrice>+{item.liningPrices?.COMPLEX}€</ItemPrice> : <ItemPrice />
                }
              </Row>
            }
          </ItemPriceContainer>
        </tr>
      )));
}

const renderItemsMobile = (category, type) => (
  category.items
    .map(item => (
      item.prices[type] && (<tr key={item.id}>
        <ItemName>{item.name}</ItemName>
        <ItemPriceContainer>
          <Row>
            <ItemPrice>{item.prices[type]}€</ItemPrice>
            {item.liningPrices?.[type] ?
              <ItemPrice>+{item.liningPrices[type]}€</ItemPrice> : <ItemPrice />
            }
          </Row>
        </ItemPriceContainer>
      </tr>
      )))
);

function getTypeName(cloth, type) {
  if (!cloth.types || cloth.types.length === 0) return '';
  const foundType = cloth.types.find(typeIt => typeIt.slug === type);
  return foundType && foundType.name;
}

function renderPricingsGridDesktop(cloth) {
  return (
    <Table>
      <TRHeader>
        <tr>
          <HeaderType />
          <HeaderType>{capitalizeFirstLetter(getTypeName(cloth, 'SIMPLE'))}</HeaderType>
          <HeaderType>{capitalizeFirstLetter(getTypeName(cloth, 'COMPLEX'))}</HeaderType>
        </tr>
      </TRHeader>
      <TRHeader>
        <tr>
          <th />
          <th>
            <Row>
              <HeaderPrice>Prix de la retouche</HeaderPrice>
              <HeaderPrice>Coût de la doublure</HeaderPrice>
            </Row>
          </th>
          <th>
            <Row>
              <HeaderPrice>Prix de la retouche</HeaderPrice>
              <HeaderPrice>Coût de la doublure</HeaderPrice>
            </Row>
          </th>
        </tr>
      </TRHeader>
      <tbody>
        {cloth.categories
          .map(category => (
            <React.Fragment key={category.name}>
              <tr>
                <CategoryName>{category.name}</CategoryName>
                <CategoryNameEmpty />
                <CategoryNameEmpty />
              </tr>
              {renderItemsDesktop(category)}
            </React.Fragment>
          ))}
      </tbody>
    </Table>
  );
}

function renderPricingsGridMobile(cloth, type) {
  return (
    <Table>
      {type &&
        <TRHeader>
          <tr>
            <HeaderType />
            <HeaderType>{capitalizeFirstLetter(getTypeName(cloth, type))}</HeaderType>
          </tr>
        </TRHeader>
      }
      <TRHeader>
        <tr>
          <th />
          <th>
            <Row>
              <HeaderPrice>Prix de la retouche</HeaderPrice>
              <HeaderPrice>Coût de la doublure</HeaderPrice>
            </Row>
          </th>
        </tr>
      </TRHeader>
      <tbody>
        {cloth.categories
          .map(category => (
            <React.Fragment key={category.name}>
              <tr>
                <CategoryName>{category.name}</CategoryName>
                <CategoryNameEmpty />
              </tr>
              {renderItemsMobile(category, type)}
            </React.Fragment>
          ))}
      </tbody>
    </Table>
  );
}

const PricingsGrid = ({ cloth, context: { isMobile } }) => {
  if (cloth.types?.length > 2) return null;
  if (!cloth.types || cloth.types.length === 0) {
    return renderPricingsGridMobile(cloth, 'DEFAULT');
  }
  if (!isMobile) {
    return renderPricingsGridDesktop(cloth);
  }
  return (
    <div>
      {renderPricingsGridMobile(cloth, 'SIMPLE')}
      {renderPricingsGridMobile(cloth, 'COMPLEX')}
    </div>
  );
};

PricingsGrid.propTypes = {
  cloth: PropTypes.shape({}).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(PricingsGrid);
